import { graphql } from "gatsby";
import React from "react";
import { Box, Grid } from "theme-ui";
import ArticleMeta from "../../components/article-meta";
import Layout from "../../components/layout";
import ColumnContainer from "../../components/layout/column-container";
import ThemeableHeader from "../../components/layout/themeable-header";
import PdfDownload from "../../components/page-blocks/pdf-download";
import Seo from "../../components/seo";
import componentMapper from "../../components/content-blocks/component-mapper";
import NewsletterSignup from "../../components/page-blocks/newsletter-signup";
import TwitterCallToAction from "../../components/page-blocks/twitter-cta";
import ReusableLatestContent from "../../components/page-blocks/reusable-latest-content";

const BlogPost = ({ data: { post } }) => (
  <Layout>
    <Seo
      title={post.title}
      description={post.additionalInfo.standfirst}
      image={post?.featuredImage?.node.mediaItemUrl}
    />
    <ThemeableHeader
      color="purple"
      title={post.title}
      subtitle={post.additionalInfo.standfirst}
      active="/"
      theme={post?.themeCategories?.nodes[0]?.name}
      backgroundImage={post?.featuredImage?.node.mediaItemUrl || false}
    />
    <Grid as="section" columns={1} gap={[4, 5]} sx={{ mb: [5, 6] }}>
      {/* <ColumnContainer>
        <pre>{JSON.stringify(post, null, 2)}</pre>
      </ColumnContainer> */}
      <ColumnContainer>
        <ArticleMeta
          date={post?.date}
          author={post?.additionalInfo?.author}
          articleTitle={post.title}
        />
      </ColumnContainer>
      <ColumnContainer>
        <Box
          className="article-body"
          as="section"
          sx={{ bg: "offWhite", p: [3, 4, 5], py: [4, 4, 5], borderRadius: 30 }}
        >
          {componentMapper(post.blocks)}
        </Box>
      </ColumnContainer>
      <Box className="hide-on-pdf">
        <ReusableLatestContent title="Read More" />
      </Box>
      <Box className="hide-on-pdf">
        <TwitterCallToAction />
      </Box>
      <Box className="hide-on-pdf">
        <PdfDownload articleTitle={post.title} />
      </Box>
      <Box className="hide-on-pdf">
        <NewsletterSignup />
      </Box>
    </Grid>
  </Layout>
);

export const query = graphql`
  query PostQuery($slug: String!) {
    post: wpPost(slug: { eq: $slug }) {
      slug
      title
      date: dateGmt(formatString: "DD/MM/YY")
      additionalInfo {
        standfirst
        author
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      themeCategories {
        nodes {
          name
          slug
        }
      }
      blocks {
        name
        ... on WpCoreQuoteBlock {
          attributes {
            ... on WpCoreQuoteBlockAttributes {
              value
              citation
            }
          }
        }
        ... on WpCoreParagraphBlock {
          attributes {
            ... on WpCoreParagraphBlockAttributes {
              content
            }
          }
        }
        ... on WpCoreEmbedBlock {
          attributes {
            ... on WpCoreEmbedBlockAttributes {
              caption
              url
            }
          }
        }
        ... on WpCoreListBlock {
          attributes {
            values
            ordered
          }
        }
        ... on WpCoreHeadingBlock {
          attributes {
            ... on WpCoreHeadingBlockAttributes {
              content
              level
              textAlign
            }
          }
        }
        ... on WpCoreTableBlock {
          saveContent
          name
        }
        ... on WpCoreGroupBlock {
          innerBlocks {
            ... on WpCoreQuoteBlock {
              name
              attributes {
                ... on WpCoreQuoteBlockAttributes {
                  value
                  citation
                }
              }
            }
            ... on WpCoreImageBlock {
              name
              attributes {
                ... on WpCoreImageBlockAttributes {
                  url
                  caption
                }
              }
            }
            ... on WpCoreTableBlock {
              saveContent
              name
            }
          }
          name
        }
        ... on WpCoreImageBlock {
          attributes {
            ... on WpCoreImageBlockAttributes {
              url
              caption
            }
          }
          name
        }
      }
    }
  }
`;

export default BlogPost;
